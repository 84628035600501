.group {
  padding: 24px;
  background-color: var(--color-basaltGray-10);
  display: flex;
  grid-gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  border-radius: 8px;
  position: relative;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 16px;
  margin-top: 8px;
  padding: 0px !important;
}

.removeIcon {
  fill: var(--color-marinaBlue-90) !important;
  width: 14px !important;
}

.email {
  width: 100%;
}

.name {
  width: calc(50% - 12px);
}

.country {
  width: 100%;
}

.icon {
  fill: var(--color-marinaBlue-50);
  width: 24px;
}

.addUser {
  color: var(--color-marinaBlue-50);
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

@media screen and (max-width: 599px) {
  .name {
    width: 100%;
  }
}
